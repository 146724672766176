import axios from 'axios';
import moment from 'moment';

import { parse } from '../../util/urlHelpers';
import { storableError } from '../../util/errors';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';

import { fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities, getListingsById, getOwnListingsById } from '../../ducks/marketplaceData.duck';
import { searchAlgoliaData } from '../../util/api';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;

const { UUID, Money } = sdkTypes;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ProviderDashBoardPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ProviderDashBoardPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ProviderDashBoardPage/FETCH_LISTINGS_ERROR';

export const FETCH_REVIEWS_SUCCESS = 'app/ProviderDashBoardPage/FETCH_REVIEWS_SUCCESS';
export const LOAD_LISTING_ID_TRANSACTION = 'app/ProviderDashBoardPage/LOAD_LISTING_ID_TRANSACTION';

export const ADD_OWN_ENTITIES = 'app/ProviderDashBoardPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  ownEntities: {},
  allRFpLength: 0,
  bidRfpLength: 0,
  acceptRfpLength: 0,
  allTransactions: [],
  allUserDistances: [],
  allUserReviews: [],
  loadListingIdTransaction: null,
  openBidIds: [],
  txIds: [],
};

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const ProviderDashBoardPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        ...payload,
        // pagination: payload.pagination,
        queryInProgress: false,
        queryListingsError: null,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state, queryInProgress: false, allUserReviews: payload
      };
    case LOAD_LISTING_ID_TRANSACTION:
      return {
        ...state,
        loadListingIdTransaction: payload
      };
    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default ProviderDashBoardPageReducer;

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsSuccess = response => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: response,
});

export const loadListingIdTransactionRequest = queryParams => ({
  type: LOAD_LISTING_ID_TRANSACTION,
  payload: queryParams
});

const allRFpTransions = [
  "transition/inquire",
  "transition/accept-bid",
  "transition/decline-bid",
  "transition/expire"
];
const bidRFpTransions = ["transition/inquire"];
const acceptRfTransions = ["transition/accept-bid"];
const bidallTransactions = [false, "inquire", "accept"];

const mapboxAccesstoken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const getDistanceOfUser = (params) => {
  const { customerOrigin, id, zipCode, listingOrigin } = params;

  try {
    // driving,  cycling
    const mode = 'driving';
    const url = `https://api.mapbox.com/directions/v5/mapbox/${mode}/${customerOrigin.lng},${customerOrigin.lat};${listingOrigin.lng},${listingOrigin.lat}?access_token=${mapboxAccesstoken}`;
    return axios.get(url)
      .then((data) => {
        if (data.data.routes.length) {
          const distance = data.data.routes[0].distance;
          // Convert distance to kilometers
          const distanceMeter = parseInt(distance);
          const distanceKm = parseInt(distance / 1000);
          const distanceMile = parseInt((distance / 1000) * 0.621371);
          // console.log(`Distance: ${distanceKm} km`);
          return { id, zipCode, distance: distanceKm, distanceMile, distanceMeter }
        } else {
          return { id, zipCode, distance: 0 }
        }
      })
      .catch(e => {
        console.error(e, '&&& getDistanceOfUser &&& => e');
        return { id, zipCode, distance: 0 }
      });
  } catch (e) {
    console.error(e, '**** getDistanceOfUser **** => e');
    return { id, zipCode, distance: 0 }
  }
};

const getImageParams = (search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return {
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    pub_typeOfListing: "event",
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }
}

export const getProperResponseOfListing = (response, config) => {
  const responseEntries = response.data.data.length ? updatedEntities({}, response.data, { listingFields: config?.listing?.listingFields }) : {};
  const resources = response.data.data.length ? response.data.data.map((st) => ({ id: st.id, type: "listing" })) : [];
  const responseArray = response.data.data.length ? denormalisedEntities(responseEntries, resources, false) : [];
  return responseArray;
};

export const getProperResponseOfTransaction = (response, config) => {
  const responseEntries = response.data.data.length ? updatedEntities({}, response.data, { listingFields: config?.listing?.listingFields }) : {};
  const resources = response.data.data.length ? response.data.data.map((st) => ({ id: st.id, type: "transaction" })) : [];
  const responseArray = response.data.data.length ? denormalisedEntities(responseEntries, resources, false) : [];
  return responseArray;
};

export const makePriceValidForIntegrationListings = (listings) => {
  if (listings && listings.length) {
    return listings.map((st) => {
      const priceMaybe = (st?.attributes?.price?.amount && st?.attributes?.price?.currency) ? new Money(st?.attributes?.price?.amount, st?.attributes?.price?.currency) : null;
      return { ...st, attributes: { ...st.attributes, price: priceMaybe } };
    })
  } else {
    return [];
  }
}


export const getAllReviewsOfMultipleUser = (allReviewOfUsers) => (dispatch, getState, sdk) => {
  const allReviews = allReviewOfUsers.map((st) => sdk.reviews.query({ listingId: new UUID(st) }));

  return Promise.all(allReviews)
    .then(res => {
      const userReviews = allReviewOfUsers.map((item, index) => {
        const rating = res[index]?.data.data.reduce((acc, curr) => acc + (curr.attributes.rating || 0), 0);

        return {
          customerId: item,
          rating: rating && res[index].data.data.length ? ((rating) / (res[index].data.data.length)).toFixed(1) : 0,
          totalRating: res[index].data.data.length
        }
      });

      dispatch(queryReviewsSuccess(userReviews));
      return res;
    })
    .catch(e => {
      console.log(e, '&&& &&& => e');
    })
};

// /rfp-manager

export const getInboxParams = (type, page) => (dispatch, getState, sdk) => {
  const apiQueryParams = {
    only: "order",
    processNames: 'default-bid',
    lastTransitions: type == "inquire" ? bidRFpTransions : type == "accept" ? acceptRfTransions : allRFpTransions,
    page: 1,
    perPage: 2,
  };

  const anotherPropsMaybe = !type ? {
    include: [
      'listing',
      'provider',
      //   'provider.profileImage',
      'customer',
      //   'customer.profileImage',
      'booking',
      'reviews',
    ],
    // 'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page: page || 1,
    perPage: RESULT_PAGE_SIZE,
  } : {};

  const queryParams = { ...apiQueryParams, ...anotherPropsMaybe };
  // console.log(queryParams, '&&& &&& => queryParams');
  return sdk.transactions.query(queryParams);
}


// Throwing error for new (loadData may need that info)
export const queryOwnListings = (queryParams, loadListingIdTransaction) => (dispatch, getState, sdk) => {

  dispatch(queryListingsRequest(queryParams))

  let allTransactionOfListing = [];
  let allRFpLength = 0;
  let bidRfpLength = 0;
  let acceptRfpLength = 0;
  let allTransactionPagination = false;

  const { page, ...rest } = queryParams;

  const allInboxPromises = [];
  bidallTransactions.forEach((st) => {
    allInboxPromises.push(dispatch(getInboxParams(st, page)));
  });

  return Promise.all(allInboxPromises)
    .then((response) => {

      const allDistanceFromUser = [];
      const allReviewOfUsers = [];

      if (response && response.length) {

        allTransactionOfListing = getProperResponseOfTransaction(response[0]);
        allTransactionPagination = response[0].data.meta
        // console.log(allTransactionOfListing, '&&& &&& => allTransactionOfListing');

        // all transaction length
        allRFpLength = response[0].data.meta.totalItems;
        bidRfpLength = response[1].data.meta.totalItems;
        acceptRfpLength = response[2].data.meta.totalItems;

        // if transaction is initiated towards that listing id 
        if (allTransactionOfListing && allTransactionOfListing.length) {
          allTransactionOfListing.forEach(element => {
            const { customer, listing } = element;
            if (customer && customer.attributes && customer.id && customer.id.uuid) {
              allReviewOfUsers.includes(customer.id.uuid) ? null : allReviewOfUsers.push(customer.id.uuid);
              const listingOrigin = listing?.attributes?.publicData?.venueAddress?.selectedPlace?.origin
              const { origin } = customer?.attributes?.profile?.publicData?.address1?.selectedPlace || {};
              if (origin && listingOrigin) {
                allDistanceFromUser.push(getDistanceOfUser({
                  customerOrigin: origin,
                  id: customer.id.uuid,
                  listingOrigin,
                }));
              }
            }
          });
        }
      }

      if (allReviewOfUsers && allReviewOfUsers.length) {
        dispatch(getAllReviewsOfMultipleUser(allReviewOfUsers));
      }

      return Promise.all(allDistanceFromUser);
    })
    .then((response) => {
      dispatch(
        queryListingsSuccess({
          pagination: allTransactionPagination,
          allRFpLength,
          bidRfpLength,
          acceptRfpLength,
          allTransactions: allTransactionOfListing,
          allUserDistances: response
        }));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};


export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const ownlistingParams = getImageParams(search, config);

  return dispatch(fetchCurrentUser())
    .then(currentUser => {
      const { publicData, protectedData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
      const { businessListingId } = protectedData || {};
      const { zipCodeRadius } = publicData || {};
      const businessListing = getOwnListingsById(getState(), [new UUID(businessListingId)]);

      if (businessListing && businessListing.length) {
        dispatch(queryListingsRequest(ownlistingParams));

        const { boothType } = (businessListing[0] && businessListing[0].id && businessListing[0].attributes.publicData) || {};
        const listingParams = {
          pub_eventBid: "open",
          pub_typeOfListing: "event",
          pub_hideFromProviders: false,
          pub_boothType: `has_any:${boothType.join(",")}`,
          pub_eventStartDateUnix: `${moment().unix()},`,
          include: ['author'],
        }

        sdk.listings
          .query({ ...listingParams })
          .then(res => {
            const { data: events } = res.data || {};
            const distancePromisify = [], serviceRadiuses = [];
            const filteredEvents = events.filter(event => event.relationships.author.data.id.uuid != currentUser.id.uuid);
            if (filteredEvents && filteredEvents.length) {
              filteredEvents.map(event => {
                const { venueAddress } = (event && event.id && event.attributes.publicData) || {};

                if (venueAddress && venueAddress.selectedPlace && venueAddress.selectedPlace.origin) {
                  if (zipCodeRadius && zipCodeRadius.length) {
                    zipCodeRadius.map(zipCode => {
                      const { origin, primaryZip, serviceRadius } = zipCode;
                      serviceRadiuses.push({ zipCode: primaryZip, id: event.id.uuid, serviceRadius });
                      distancePromisify.push(getDistanceOfUser({
                        customerOrigin: origin,
                        zipCode: primaryZip,
                        id: event.id.uuid,
                        listingOrigin: venueAddress.selectedPlace.origin,
                      }));
                    });
                  }
                }
              });

              if (distancePromisify && distancePromisify.length) {
                Promise.all(distancePromisify)
                  .then(distancePromisified => {
                    if (distancePromisified && distancePromisified.length) {
                      const filteredListingIds = distancePromisified
                        .sort((a, b) => a.distanceMeter - b.distanceMeter)
                        .filter(p => {
                          const index = serviceRadiuses.findIndex(sr => sr.id == p.id && sr.zipCode == p.zipCode && (p.distanceMeter == 0 || (p.distanceMeter && sr.serviceRadius && sr.serviceRadius >= p.distanceMeter)));
                          if (index > -1) return p;
                        });
                      const filteredEvents = [];
                      events.map(event => {
                        const index = filteredListingIds.findIndex(f => f.id == event.id.uuid);
                        if (index > -1) {
                          Object.assign(event.attributes.publicData, {
                            distanceMile: filteredListingIds[index].distanceMile
                          });
                          return filteredEvents.push(event);
                        }
                      });
                      Object.assign(res.data, { data: filteredEvents });
                      dispatch(addMarketplaceEntities(res));
                      return dispatch(queryListingsSuccess({
                        openBidIds: filteredEvents.map(f => f.id)
                      }));
                    }
                  });
              }
            } else {
              dispatch(queryListingsSuccess({
                openBidIds: []
              }));
            }

          });

        try {
          searchAlgoliaData({
            querySearch: '',
            filters: {
              facetFilters: ['providerId:' + currentUser.id.uuid]
            },
            indexName: process.env.REACT_APP_ALGOLIA_TRANSACTIONS_INDEX,
          })
            .then(res => {
              const { hits: transactions } = res.data;
              if (transactions && transactions.length) {
                const listingIds = transactions.map(transaction => transaction.eventListingId).join(',');
                return sdk.listings.query({
                  ids: listingIds,
                  include: ['author']
                })
                  .then(res => {
                    const { data: events } = res.data;
                    transactions.map(transaction => {
                      const index = events.findIndex(d => d.id.uuid == transaction.eventListingId);
                      if (index > -1) {
                        Object.assign(res.data.data[index].attributes, { transaction });
                        dispatch(addMarketplaceEntities(res));
                        return dispatch(queryListingsSuccess({
                          txIds: events.map(event => event.id)
                        }));
                      }
                    })
                  });
              }
              
            })
            .catch(err => {
              console.log(err, '**** searchAlgoliaData **** => err');
            });
        } catch (error) {
          console.error(error, '**** searchAlgoliaData **** => error');
          return;
        }
      }

      return currentUser;
    })
    .catch(e => {
      console.error(e, '**** **** => e');
      dispatch(queryListingsError(storableError(e)));
    });
};
