import { rfpListings, actionRFPByAdmin } from '../../util/api';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/DashboardPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/DashboardPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/DashboardPage/FETCH_LISTINGS_ERROR';

export const SET_ACTIVE_LISTING_ID = 'app/DashboardPage/SET_ACTIVE_LISTING_ID';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  headerCount: {},
  rfpIds: [],
  reviews: [],
  transactionIds: [],
  matchedBusiness: [],
  activeListingId: null,
  activeListingInProgress: false,
};

const resultIds = data => data.data.map(l => l.id);
const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const DashboardPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        headerCount: {},
        rfpIds: [],
        reviews: [],
        transactionIds: [],
        matchedBusiness: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        ...payload,
        queryInProgress: false,
        queryListingsError: null,
        activeListingInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        activeListingInProgress: false,
        queryListingsError: payload
      };

    case SET_ACTIVE_LISTING_ID:
      return {
        ...state,
        activeListingInProgress: true,
        activeListingId: payload
      }

    default:
      return state;
  }
};

export default DashboardPageReducer;

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const setActiveListingId = queryParams => ({
  type: SET_ACTIVE_LISTING_ID,
  payload: queryParams
});

export const queryAdminListings = (queryParams, activeListingId) => (dispatch, getState, sdk) => {
  activeListingId
    ? dispatch(setActiveListingId(activeListingId))
    : dispatch(queryListingsRequest(queryParams));

  const { listingId = activeListingId, tab, ...rest } = queryParams;
  const payload = { ...rest, listingId };
  const { currentUser } = getState().user;
  const currentUserId = currentUser && currentUser.id && currentUser.id.uuid;
  const { isAdmin } = (currentUserId && currentUser.attributes.profile.publicData) || {};

  if (!isAdmin || tab != 'admin') {
    Object.assign(payload, { authorId: currentUserId });
  } else {
    Object.assign(payload, { tab });
  }

  return rfpListings(payload)
    .then(res => {
      if (res && res.data) {
        const {
          headerCount = {},
          transactions,
          listingsRFP,
          reviews,
          matchedBusiness,
          successData = {},
        } = res.data;

        if (headerCount && Object.keys(headerCount).length) {
          Object.assign(successData, { headerCount });
        }
        if (reviews) {
          Object.assign(successData, { reviews });
        }
        if (listingsRFP) {
          dispatch(addMarketplaceEntities({ data: listingsRFP }));
          Object.assign(successData, {
            pagination: listingsRFP.meta,
            rfpIds: resultIds(listingsRFP),
          });
        }
        if (matchedBusiness && matchedBusiness.length) {
          Object.assign(successData, { matchedBusiness });
        } else {
          Object.assign(successData, { matchedBusiness: [] });
        }
        if (transactions) {
          dispatch(addMarketplaceEntities({ data: transactions }));
          Object.assign(successData, { transactionIds: entityRefs(transactions.data) });
        } else {
          Object.assign(successData, { transactionIds: [] });
        }
        dispatch(queryListingsSuccess(successData));
      }
      return res;
    })
    .catch(e => {
      console.error(e, '**** queryAdminListings **** => e');
      // dispatch(setActiveListingId(null));
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const actionRFPListingByAdmin = (payload, activeListingId) => (dispatch) => {
  try {
    const { queryParams, ...rest } = payload;
    actionRFPByAdmin(rest)
      .then(res => {
        dispatch(queryAdminListings(queryParams, activeListingId));
        return res;
      })
      .catch(err => console.error(err, '=== ===> err'))
  } catch (error) {
    throw error;
  }
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const { tab } = params;
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  return dispatch(fetchCurrentUser())
    .then(currentUser => {
      const { activeListingId } = getState().DashboardPage;
      dispatch(queryAdminListings({
        tab,
        page,
        perPage: RESULT_PAGE_SIZE,
        // states: "published",
        pub_typeOfListing: "event",
        ...queryParams,
      }, activeListingId));

      return currentUser;
    })
    .catch(e => e);
};
